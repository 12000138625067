
.App {
  text-align: center;
}


.Content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  font-size: 24px;
  font-weight: bold;
}

.Loading {
  position: absolute;
  left: 50%;
  top: 50%;
}